
/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.pagination a{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 32px;
    height: 32px;
    font-size: 1.6rem;
    font-weight: 700;
    padding: 0 5px;
    -webkit-transition: all ease-out .15s;
    transition: all ease-out .15s;
}
.pagination a:not(.pagination__current) {
    font-weight:400;
    color: #7e7e7e;
    cursor: pointer;
}
.pagination a:not(.pagination__current):hover{
    background-color: #f2f2f2;
}
.pagination__backward, .pagination__prev, .pagination__next, .pagination__forward{
    color: #222 !important;
    border:1px solid #ddd;
    border-radius: 6px;
}
.pagination__backward .v-icon,
.pagination__prev .v-icon,
.pagination__next .v-icon,
.pagination__forward .v-icon{
    font-size: 1.8rem;
    color: inherit;
}
.pagination .pagination__prev{
    margin-left:10px;
    margin-right: 16px;
}
.pagination .pagination__next{
    margin-left: 16px;
    margin-right:10px;
}
.pagination .pagination__current {
    color: var(--v-primary-base);
    cursor: default;
}
// .pagination .pagination__current::after {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     -webkit-transform: translate(-50%, 0);
//     transform: translate(-50%, 0);
//     display: inline-block;
//     width: 10px;
//     height: 2px;
//     background-color: var(--v-primary-base);
// }
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .pagination{
        margin-top: 70px;
    }
}
